'use client';
import "./page.css";
import { Link } from "react-router-dom";

function Page() {
  return (
    <section className="LoginSection">
      <div className="form-box">
        <div>
          <p className="LoginHeaderName">Яг одоо хажууд чинь байгаа<br/> залууд итгэж байна уу</p>
          <div className="login">
        <Link to="/Yes">
            <button type="submit" className="SignIn" >
            Тийм
            </button>
            </Link>
          </div>
          <div className="login">
            <Link to="/No">
            <button type="submit" className="SignIn" >
            Үгүй
            </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Page;
