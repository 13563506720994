'use client';
import "./No.css";

function No() {
  return (
    <section className="Section">
      <div className="formbox"/>
        <div>

      </div>
    </section>
  );
}

export default No;
