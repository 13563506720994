'use client';
import "./Yes.css"
import { Link } from "react-router-dom";

function Yes() {
  return (
    <section className="LoginSection">
      <div className="form-box">
        <div>
          <p className="LoginHeaderName">Надтай үерхээч </p>
          <div className="login">
            <Link to="/Yes2">
            <button type="submit" className="SignIn" >
            Тийм
            </button>
           </Link>
          </div>
          <div className="login">
            <Link to="/No">
            <button type="submit" className="SignIn" >
            Үгүй
            </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Yes;
