import "./Yes2.css";

function yes2() {
  return (
    <section className="LoginSection">
    <div className="formbox">
        <image src="../image/congrations.jpg" className="Const"></image>
      <div>
        <h1 className="Htag">Congratulations</h1>
        <p className="LoginHeaderName">Үүнээс хойш Санжидмаа албан ёсоор Мөнгөнтулгын найз охин боллоо</p>
      </div>
    </div>
  </section>
  );
}

export default yes2;
