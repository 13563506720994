import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Login/Login";
import Page from "./Page2/Page";
import Yes from "./Yes/Yes";
import Yes2 from "./Yes2/yes2";
import No from "./No/No";
function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/Page2" element={<Page/>}/>
                <Route path="/Yes" element={<Yes/>}/>
                <Route path="/No" element={<No/>}/>
                <Route path="/Yes2" element={<Yes2/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default App;