'use client';
import "./Login.css";
import { Link } from "react-router-dom";

function Login() {
  return (
    <section className="LoginSection">
      <div className="form-box">
        <div>
          <p className="LoginHeaderName">Сайна уу? Хөөрхөнөө</p>
          <div className="inputbox">
            <input type="text" name="username" required />
            <label>Нэрээ оруулаарай Хөөрхөнөө</label>
          </div>
          <div className="login">
            <Link to="/Page2"> 
            <button type="submit" className="SignIn" >
            Үргэлжлүүлэх
            </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
